

.barGraphElementWrapper {
    height: fit-content;
    display: grid;
    margin-right: 20px;
    width: fit-content;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
}

.barGraphBarContainer {
    height: fit-content;
    align-self: end;
}

.barGraphBarPercentage {
    font-size: 9px;
    margin: auto;
    width: fit-content;
}

.barGraphBar {
    border: 1px solid #CCCCCC;
    width: 23px;
}

.barGraphCaseIdText {
    margin: 10px 0px 0px 0px;
    font-size: 14px;
    justify-self: center;
    grid-column: span 2;
}