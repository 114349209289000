

.newCaseSelectorPopupPageContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
}

.newCaseSelectorPopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.newCaseSelectorPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 400px;
    background-color: var(--new-secondary-background-color);
    display: grid;
    grid-template-rows: auto auto;
    border-radius: 3px;
    padding: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.newCaseSelectorPopupImage {
    width: 100%;
    object-fit: cover;
    object-position: center;
    /* max-height: 200px; */
}

.newCaseSelectorPopupBodyWrapper {
    padding: 15px 20px;
}

.newCaseSelectorPopupTitle {
    margin: 5px 0px;
    color: var(--new-primary-color);
    font-size: 26px;
}

.newCaseSelectorPopupDescription {
    color: var(--new-text-color);
    margin: 20px 0px;
    margin-top: 15px;
    white-space: pre-wrap;
}

.newCaseSelectorPopupButtonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    height: 40px;
}

.newCaseSelectorPopupButton {

}

.newCaseSelectorPopupSpinnerContainer {
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}