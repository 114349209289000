

.performanceOverTimeTitleValueWrapper {
    margin: 20px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}

@media(max-width: 600px) {
    .performanceOverTimeTitleValueWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 20px;
    }
}