

.reportCardPageContainer {
    padding-bottom: 20px;
    background-color: white;
}

.reportCardHeaderContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    height: 70px;
    padding: 0px 2.5%;
    border-bottom: 1px solid #CCCCCC;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 10;
}

@media(max-width: 500px) {
    .reportCardHeaderContainer {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        height: fit-content;
        row-gap: 10px;
        padding: 10px 2.5%;
    }
}

.reportCardHeaderText {
    margin: 0px;
    font-size: 24px;
}

.reportCardHeaderButton {
    cursor: pointer;
    width: fit-content;
    display: inline-block;
}

.reportCardOverviewTitleValueWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    width: 95%;
    margin: auto;
    margin-top: 20px;
}

@media(max-width: 900px) {
    .reportCardOverviewTitleValueWrapper {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 20px;
    }
}

@media(max-width: 600px) {
    .reportCardOverviewTitleValueWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}

.reportCardModuleContainer {
    width: 95%;
    margin: auto;
    border-radius: 5px;
    display: grid;
    grid-template-rows: 50px auto;
    margin-top: 20px;
    border: 1px solid #CCCCCC;
}

.reportCardModuleHeaderContainer {
    height: 100%;
    width: 100%;
    background-color: #EEEEEE;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 5px 5px 0px 0px;
    display: grid;
}

.reportCardModuleHeaderText {
    margin: 0px;
    align-self: center;
    margin-left: 10px;
    font-weight: bold;
    font-size: 18px;
}

.reportCardModuleBodyContainer {
    padding: 20px;
    overflow: hidden;
}

.reportCardThreeTitleValueWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    width: 95%;
    margin: auto;
    margin-top: 20px;
}

@media(max-width: 775px) {
    .reportCardThreeTitleValueWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 20px;
    }
}

.reportCardTitleValueContainer {
    background-color: #EEEEEE;
    border: 1px solid #CCCCCC;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 15px 10px;
    border-radius: 5px;
    width: calc(100% - 20px);
}

.reportCardTitleValueText {
    margin: 0px;
    font-size: 16px;
}

.reportCardTitleValueText:nth-child(2) {
    margin-left: 10px;
    justify-self: end;
}

.dualModuleWrapper {
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}

@media(max-width: 850px) {
    .dualModuleWrapper {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
    }

    .testPrepCalculatorWrapper {
        grid-template-rows: 1fr;
    }
}

.reportCardErrorWrapper {
    margin: auto;
    padding: 0px 20px;
    border: 1px solid #CCCCCC;
    width: fit-content;
    margin-top: 10px;
}

.reportCardErrorMessage {
    text-align: center;
}

.reportCardLoadingContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-items: center;
}