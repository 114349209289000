

.caseListPageContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
    overflow: hidden;
    background-color: var(--new-primary-background-color);
    position: relative;
}

.caseListSidebar {
    background-color: var(--new-tertiary-background-color);
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    padding: 15px;
    overflow-y: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

/* @media(max-width: 950px) {
    .caseListPageContainer {

    }
} */

.caseListLogoContainer {
    display: grid;
    grid-template-columns: 1fr;
}

.caseListLogo {
    height: 40px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.caseListDrawerCloseButton {
    display: none;
}

.caseListMobileNavButtonContainer {
    display: none;
}

.caseListMobileDrawerPageContainer {
    display: none;
}

.caseListSidebarWrapper {
    user-select: none;
    height: 100%;
    overflow-y: auto;
}

.caseListStyleToggle {
    display: grid;
    height: 40px;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: relative;
}

.caseListStyleToggleOption {
    height: 100%;
    width: 100%;
    color: white;
    background-color: var(--new-off-text-color);
    border: none;
    cursor: pointer;
}

.caseListStyleOptionSelected {
    background-color: var(--new-secondary-color);
}

.caseListStyleToggleLoadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.caseListDropdownWrapper {
    display: grid;
    grid-template-rows: auto auto;
}

.dropdownWithButton {
    grid-template-columns: auto 50px;
}

.caseListDropdownWrapper > select {
    grid-row: 2;
}

.caseListSortDirectionButton {
    grid-column: 2;
    grid-row: 2;
    margin-left: 10px;
    height: 100%;
    cursor: pointer;
    background-color: var(--new-off-text-color);
    border: none;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    color: var(--new-secondary-color);
    font-size: 18px;
    position: relative;
}

.caseListSortDirectionIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media(max-width: 850px) {
    .caseListMobileDrawerPageContainer {
        position: absolute;
        display: initial;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        overflow: hidden;
    }
    
    .caseListMobileDrawerBackdrop {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.7);
    }
    
    .caseListMobileDrawerContainer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 90%;
        width: 100%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        z-index: 2;
    }

    .caseListPageContainer {
        grid-template-columns: 1fr;
    }

    .caseListSidebar {
        width: calc(100% - 30px);
        border-radius: 10px 10px 0px 0px;
    }

    .caseListSidebarWrapper {
        display: none;
    }

    .caseListLogoContainer {
        grid-template-columns: 1fr auto;
    }

    .caseListLogo {

    }

    .caseListDrawerCloseButton {
        display: initial;
        height: 35px;
        width: 35px;
        background-color: var(--new-secondary-background-color);
        border: 0px;
        border-radius: 3px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        position: relative;
    }

    .caseListDrawerCloseButton::before, .caseListDrawerCloseButton::after {
        position: absolute;
        content: "";
        height: 25px;
        width: 2px;
        border-radius: 2px;
        background-color: var(--new-primary-color);
    }
    
    .caseListDrawerCloseButton::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    
    .caseListDrawerCloseButton::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    

    .caseListMobileNavButtonContainer {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 10px;
        margin: 20px 0px;
    }

    .caseListMobileNavButton {
        height: 40px;
        width: 100%;
        background-color: var(--new-secondary-background-color);
        color: var(--new-text-color);
        display: grid;
        grid-template-columns: 40px 1fr;
        align-items: center;
        justify-items: center;
        border: 0px;
        border-radius: 3px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .caseListDropdownWrapper {

    }

}

.caseListSidebarLabel {
    display: block;
    color: var(--new-secondary-off-text-color) !important;
    /* margin-left: 2px; */
}

.caseListDropdown {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    padding-left: 5px;
    width: 100%;
    height: 40px;
    color: var(--new-text-color);
    border: 0px;
}

.caseListSidebarHeader {
    font-size: 24px;
    font-weight: bold;
    color: var(--new-secondary-text-color);
    margin: 10px 0px;
}

.caseListSidebarSectionBreak {
    height: 5px;
    width: 100%;
}

.caseListSidebarCategoryWrapper {
    padding: 5px 10px;
    display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: 12px;
    align-items: center;
    margin-bottom: 5px;
    font-size: 15px;
    cursor: pointer;
}

.caseListSidebarCategoryWrapper:nth-last-child(1) {
    margin-bottom: 0px;
}

.caseListSidebarCategoryIcon {
    height: 25px;
    width: 25px;
}

.checkboxContainer {
    justify-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
}

.checkboxCheck {
    color: var(--new-primary-color);
}

.caseListSidebarCategoryIcon {
    color: var(--new-secondary-color);
    font-size: 32px;
}

.caseListSidebarText {
    color: var(--new-secondary-off-text-color);
    margin: 0px;
}

.caseListSidebarSubtext {
    font-size: 14px;
    color: var(--new-secondary-off-text-color);
    margin: 0px;
}

.caseListSidebarTagWrapper {
    display: grid;
    grid-template-columns: 50px 1fr;
    margin-top: 10px;
    align-items: center;
    min-height: 25px;
    font-size: 15px;
    cursor: pointer;
}

.sidebarToggleDisabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: initial;
}

.caseListSidebarTagWrapper:nth-last-child(1) {
    padding-bottom: 20px;
}

.caseListSidebarAddTagButton {
    width: 100%;
    height: 32px;
    font-size: 14px;
    margin-top: 10px;
}

/* Begin Case List */

.caseListBodyPageWrapper {
    display: grid;
    grid-template-rows: 1fr 50px;
    height: 100%;
    position: relative;
    overflow-x: hidden;
}

.caseListHeaderButtonContainer {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 10px;
    column-gap: 10px;
    background-color: rgba(0, 0, 0, 0);
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-items: end;
    z-index: 10;
    height: 70px;
}

.caseListHeaderButton {
    height: 40px;
    width: 150px;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    justify-items: center;
    color: var(--new-text-color) !important;
}

.caseListHeaderMobileButton {
    display: none;
}

.caseListHeaderBurgerButton {
    display: none;
}

@media (max-width: 940px) {
    .caseListHeaderButton {
        display: none;
    }

    .caseListHeaderMobileButton {
        width: 45px;
        height: 40px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        color: var(--new-primary-color) !important;
    }
}

@media(max-width: 850px) {
    .caseListHeaderButtonContainer {
        column-gap: 0px;
    }

    .caseListHeaderMobileButton {
        display: none;
    }

    .caseListHeaderBurgerButton {
        width: 45px;
        height: 40px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        color: var(--new-primary-color) !important;
    }
}

.caseListHeaderButtonIcon {
    height: 22px;
    width: 22px;
    color: var(--new-primary-color);
}

.caseListLoadingContainer {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    margin: auto;
    background-color: var(--new-primary-background-color);
}

.caseListLoadingWrapper {
    height: 110px;
    width: fit-content;
    align-self: center;
    justify-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 70px 40px;
}

.caseListContainer {
    background-color: var(--new-primary-background-color);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    row-gap: 10px;
    column-gap: 10px;
    padding: 0px 10px;
    overflow-y: auto;
    padding-bottom: 10px;
    overflow-x: hidden;
}

.caseListSectionHeaderContainer {
    position: sticky;
    top: 0;
    height: 70px;
    background-color: var(--new-primary-background-color);
    left: 0;
    width: calc(100% + 20px);
    transform: translateX(-10px);
    grid-column-start: 1;
    grid-column-end: 4;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    align-items: center;
    padding: 0px 10px;
    z-index: 9;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.10); */
}

.castListSectionLogoText {
    font-size: 32px;
    color: var(--new-primary-color);
    margin: 0px;
    font-weight: bold;
}

@media (max-width: 1150px) {
    .caseListContainer {
        grid-template-columns: 1fr 1fr;
    }

    .caseListSectionHeaderContainer {
        grid-column-end: 3;
    }
}

@media (max-width: 900px) {
    .caseListContainer {
        grid-template-columns: 1fr;
    }

    .caseListSectionHeaderContainer {
        grid-column-end: 2;
    }
}

@media (max-width: 850px) {
    .caseListContainer {
        grid-template-columns: 1fr 1fr;
    }

    .caseListSectionHeaderContainer {
        grid-column-end: 3;
    }
}

@media (max-width: 600px) {
    .caseListContainer {
        grid-template-columns: 1fr;
    }

    .caseListSectionHeaderContainer {
        grid-column-end: 2;
    }
}

@media (max-width: 385px) {
    .caseListSectionHeaderContainer {
        column-gap: 0px;
    }
}

.caseListSectionIcon {
    height: 40px;
    width: 40px;
    margin: 5px;
    display: inline-block;
    color: var(--new-primary-color);
    vertical-align: top;
}

.caseListSectionHeader {
    font-size: 32px;
    display: inline-block;
    margin: 5px;
    vertical-align: top;
    color: var(--new-tertiary-off-text-color);
}

@media (max-width: 1020px) {
    .caseListSectionHeader {
        font-size: 24px;
    }
}

@media (max-width: 385px) {
    .caseListSectionIcon {
        height: 30px;
        width: 30px;
    }

    .caseListSectionHeader {
        font-size: 20px;
    }
}

.caseListCaseContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    padding-top: 0px;
    position: relative;
    height: 225px;
    cursor: pointer;
    transition-duration: 150ms;
    color: var(--text-color);
}

.caseListCaseContainer:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.55);
}

@media(min-width: 800px) {
    .caseListCaseContainer:hover {
        transform: scale(1.02) !important;
        z-index: 5;
    }
}

.caseListCaseHeaderWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 7px;
    align-items: center;
    padding-top: 5px;
}

.caseListCaseId {
    margin: 0px;
    font-size: 40px;
    color: var(--new-primary-color);
    display: inline-block;
    transition-duration: 150ms;
}

.caseListCaseTitle {
    display: inline-block;
    font-size: 22px;
    margin: 0px;
    color: var(--new-case-text-color);
}

.caseListMetaDetailsWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 8px;
    border-bottom: 1px solid #707070;
    padding-bottom: 5px;
}

.caseListCaseIcon {
    height: 20px;
    width: 20px;
    color: var(--new-primary-color);
    transition-duration: 150ms;
}

.caseListWhite {
    /* color: white; */
}

.caseListMetaText {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--new-case-text-color);
}

.caseListCaseDiagnosisTextContainer {
    position: relative;
    height: fit-content;
    width: fit-content;
}

.caseListCaseDiagnosisBlur {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--new-secondary-background-color);
    opacity: 0.6;
    z-index: 2px;
}

.caseListCaseDiagnosisText {
    margin: 10px 0px;
    font-size: 14px;
    color: var(--new-case-text-color);
}

.caseListDiagnosisTextHidden {
    filter: blur(3px);
}

.caseListCaseFooterContainer {
    background-color: var(--new-primary-background-color);
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: 5px;
    border-radius: 0px 0px 3px 3px;
    color: var(--new-text-color);
    transition-duration: 150ms;
}

.caseListCaseFooterSelected {
    background-color: var(--new-primary-color);
    color: white;
}

.caseListCaseFooterReviewLaterContainer {
    align-self: center;
    display: grid;
    width: fit-content;
    grid-template-columns: 30px 1fr;
    align-items: center;
    justify-items: center;
}

.caseListCaseReviewLaterText {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    /* color: var(--new-text-color); */
    color: var(--new-tertiary-off-text-color);
}

.caseListCaseReviewLaterTextSelected {
    color: white;
}

.caseListCaseFooterReviewLaterLoadingContainer {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    width: 30px;
}

.caseListCaseButton {
    height: 28px;
    padding: 0px 20px;
    font-size: 12px;
    align-self: center;
}

.caseListHideCaseButton {
    height: 28px;
    width: 28px;
    align-self: center;
    margin-right: 5px;
    grid-column: 3;
    grid-row: 1;
    position: relative;
}

.caseListHideCaseButtonIcon {
    color: var(--new-primary-color);
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.caseListHideCaseSpinnerContainer {
    display: grid;
    align-items: center;
    justify-items: center;
    margin-right: 5px;
    height: 28px;
    width: 28px;
    align-self: center;
}

.caseListCaseLastCompletedDateContainer, .caseListCaseAverageGradeContainer {
    position: absolute;
    right: 0;
    padding: 5px 20px;
    border-radius: 3px 0px 0px 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    color: var(--new-secondary-text-color);
    transition-duration: 150ms;
}

.caseListCaseLastCompletedDateContainer {
    top: 57%;
    background-color: var(--new-secondary-color);
}

.caseListCaseAverageGradeContainer {
    top: 70%;
    background-color: var(--new-primary-color);
}

.caseListCaseLastCompletedDateText, .caseListCaseAverageGradeText {
    margin: 0px;
    font-size: 12px;
    /* font-weight: 500; */
}

.caseListGradeContainerSelected {
    /* background-color: white; */
    /* color: var(--new-text-color); */
}

.caseListCaseAverageGradeText {
    font-size: 10px;
}

.caseListFooterContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: end;
    align-items: center;
    padding: 0px 10px;
    column-gap: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--new-secondary-background-color);
    z-index: 2;
}

.caseListFooterButton {
    height: 35px;
    min-width: 200px;
    /* display: none; */
    transition-duration: 150ms;
}

.caseListFooterLoaderWrapper {
    min-width: 200px;
    height: 35px;
    display: grid;
    align-items: center;
    justify-items: center;
}

.caseListFooterSecondaryButton {

}

.caseListFooterPrimaryButton {

}

@media(max-width: 950px) {
    .caseListFooterButton {
        min-width: 180px;
    }
}

@media(max-width: 850px) {
    .caseListFooterContainer {
        grid-template-columns: 1fr 1fr;
    }

    .caseListFooterButton {
        min-width: 0px;
        width: 100%;
    }
}

.confirmationPopupCheckboxWrapper {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    margin-top: 15px;
}

.confirmationPopupCheckboxLabel {
    margin: 0px;
    color: var(--new-text-color);
}

.caseListLoadingText {
    color: var(--new-text-color);
}

.caseListNoCasesContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin-top: 70px;
}

.caseListNoCasesText {
    margin: 15px 0px;
}

.caseListViewHiddenCasesButton {
    height: 40px;
    width: 200px;
}

/* .caseListNoCasesTitle {
    font-size: 26px;
}

.caseListNoCasesContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 15px;
    width: 95%;
    max-width: 400px;
    left: 20%;
}

.caseListNoCasesText {
    margin: 20px 0px;
}

.caseListViewHiddenCasesButton {
    height: 40px;
    width: 200px;
} */

.caseListRowStyleListContainer {
    background-color: var(--new-primary-background-color);
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    row-gap: 7px;
    padding: 0px 10px;
    overflow-y: auto;
    padding-bottom: 10px;
    overflow-x: auto;
}

.caseListRowSectionHeaderContainer {
    position: sticky;
    top: 0;
    /* height: 100px; */
    background-color: var(--new-primary-background-color);
    left: 0;
    width: 100%;
    transform: translateX(-10px);
    display: grid;
    grid-template-rows: auto auto;
    column-gap: 5px;
    align-items: center;
    padding: 0px 10px;
    z-index: 9;
    padding-bottom: 5px;
}

.caseListRowHeaderContainer {
    height: 70px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
}

.caseListRowLabelContainer {
    /* height: 30px; */
    display: grid;
    padding: 0px 0px;
    justify-items: start;
    align-items: center;
    column-gap: 10px;
}

.caseListRowLabel {
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
    /* text-align: center; */
    cursor: pointer;
    color: var(--new-text-color);
}

.caseListRowContainer {
    display: grid;
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    border-radius: 3px;
    height: fit-content;
    min-height: 45px;
    /* grid-template-columns: 50px 100px 25% 25% 15% 10% calc(25% - 150px); */
    /* grid-template-columns: 50px 1fr 2fr 2fr 1fr 1fr 100px; */
    column-gap: 10px;
    align-items: center;
    justify-items: start;
    cursor: pointer;
    transition-duration: 150ms;
}

.caseListRowContainer:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.55);
}

@media(min-width: 800px) {
    .caseListRowContainer:hover {
        transform: scale(1.01) !important;
        z-index: 5;
    }
}

.caseListRowIconContainer {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    width: 50px;
    border-radius: 3px 0px 0px 3px;
    background-color: var(--new-primary-color);
}

.caseListRowIcon {
    color: white;
    height: 25px;
    width: 25px;
}

.caseListRowCaseNumber {
    margin: 0px;
    /* text-align: center; */
    font-size: 24px;
    color: var(--new-primary-color);
    justify-self: center;
}

.caseListRowValue {
    margin: 0px;
    /* text-align: center; */
    font-size: 15px;
    padding: 10px 0px;
    color: var(--new-text-color);
}

.caseListRowMoreOptionsButton {
    height: 100%;
    width: 100%;
    border: 0px;
    box-shadow: inset 1px 0px 1px 0px rgba(0, 0, 0, 0.25);
    background-color: var(--new-primary-background-color);
    cursor: pointer;
}

.caseListRowMoreOptionsIcon {
    height: 17px;
    width: 17px;
    color: var(--new-text-color);
}

.caseListCaseSelected {
    background-color: var(--case-selected-color);
}




.moreOptionsPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    width: 200px;
    z-index: 20;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: grid;
    grid-auto-flow: row;
    row-gap: 10px;
}

.moreOptionPopupButton {
    height: 40px;
    width: 100%;
    border: 0px;
    background-color: var(--new-secondary-background-color);
    color: var(--new-text-color);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition-duration: 150ms;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}
