

.actionLogContainer {
    display: grid;
    grid-template-columns: 400px auto;
    margin: 15px 40px;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0px;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.actionLogTableContainer {
    width: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    max-height: 400px;
    overflow-y: auto;
}

.actionLogTitle {
    margin: 0px;
    padding: 10px;
    font-size: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--new-secondary-background-color);
    z-index: 1;
    color: var(--new-text-color);
    position: sticky;
    top: 0;
}

.actionLogTableWrapper {

}

.actionLogTableItem {
    padding: 10px;
    background-color: var(--new-secondary-background-color);
    display: grid;
    grid-template-columns: 55% 45%;
    cursor: pointer;
    color: var(--new-text-color);
}

.actionLogTableItem:hover {
    filter: brightness(0.95);
}

.actionLogTableItem:nth-child(even) {
    filter: brightness(0.9);
}

.actionLogTableItem:nth-child(even):hover {
    filter: brightness(0.85);
}

.actionLogTableItemSelected {
    background-color: var(--new-primary-color) !important;
    filter: brightness(1) !important;
    cursor: initial;
    color: white;
}

.actionLogTableItemTimeWrapper {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 3px;
    align-self: center;
}

.actionLogTableItemDay {
    margin: 0px;
}

.actionLogTableItemTime {
    margin: 0px;
    font-size: 14px;
    opacity: 0.65;
}

.actionLogTableItemText {
    margin: 0px;
    font-weight: 500;
    font-size: 16px;
    align-self: center;
}

.actionLogTimelineWrapper {
    align-self: center;
    padding: 50px 25px;
    position: relative;
    width: calc(100% - 25px);
    overflow-x: auto;
    padding-right: 0px;
}

@media(max-width: 750px) {
    .actionLogContainer {
        margin: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: auto 350px;
    }

    .actionLogTableContainer {
        grid-row: 2;
    }

    .actionLogTimelineWrapper {
        padding: 25px;
        padding-right: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.actionLogTimelineHeader {
    margin: 0px;
    position: sticky;
    left: 0px;
    color: var(--new-text-color);
}

.actionLogTimelineContainer {
    margin-top: 25px;
    padding: 0px 25px;
    margin-right: 25px;
    width: fit-content;

}

.actionLogTopRowWrapper, .actionLogBottomRowWrapper {
    position: relative;
    display: grid;
    grid-auto-flow: column;
}

/* Css for when there are an odd amount of events */

.actionLogOddTopRowWrapper {
    top: 1px;
}

.actionLogOddBottomRowWrapper {
    top: -2px;
    padding-left: 175px;
    width: calc(100% - 350px);
}


/* Css for when there are an even amount of events */

.actionLogEvenTopRowWrapper {
    top: 1px;
    padding-right: 175px;
}

.actionLogEvenBottomRowWrapper {
    top: -2px;
    padding-left: 175px;
}

.actionLogTimeLine {
    height: 2px;
    width: 100%;
    background-color: var(--new-text-color);
}

.actionLogTopRowItem, .actionLogBottomRowItem {
    position: relative;
    width: 350px;
    border-left: 2px solid var(--new-text-color);
    padding-left: 10px;
    cursor: pointer;
    height: fit-content;
}

.actionLogTopRowItem:hover, .actionLogBottomRowItem:hover {
    filter: contrast(0.95);
}

.actionLogTopRowItem {
    padding-bottom: 25px;
    border-bottom: 3px solid var(--new-text-color);
    align-self: end;
}

.actionLogBottomRowItem {
    padding-top: 25px;
    border-top: 3px solid var(--new-text-color);
    align-self: start;
}

.actionLogTopRowItem::after, .actionLogBottomRowItem::before {
    content: "";
    position: absolute;
    left: -11px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: var(--new-text-color);
}

.actionLogRowItemSelected {
    border-left-color: var(--new-secondary-color);
}

.actionLogRowItemSelected::before, .actionLogRowItemSelected::after {
    background-color: var(--new-secondary-color);
}

.actionLogTopRowItem::after {
    bottom: -11px;
}

.actionLogBottomRowItem::before {
    top: -11px;
}

.actionLogItemTitle {
    margin: 0px;
    font-size: 18px;
    color: var(--new-text-color);
}

.actionLogItemTitleSelected {
    color: var(--new-secondary-color);
}

.actionLogItemTime {
    margin: 0px;
    opacity: 0.6;
    font-size: 14px;
    color: var(--new-text-color);
}
