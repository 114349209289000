

.incompleteCasePopupPageContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.incompleteCasePopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.incompleteCasePopupContainer {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: calc(95% - 20px);
    max-width: 450px;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    padding-top: 5px;
}

.incompleteCasePopupHeaderWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    align-items: center;
}

.incompleteCasePopupId {
    font-size: 44px;
    color: var(--new-primary-color);
    margin: 0px;
}

.incompleteCasePopupTitle {
    margin: 0px;
    font-size: 22px;
    color: var(--new-text-color);
}

.incompleteCasePopupMetaWrapper {
    display: grid;
    font-size: 14px;
    margin: 0px;
    height: fit-content;
    border-bottom: 1px solid var(--new-off-text-color);
    padding-bottom: 10px;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    align-items: center;
}

.incompleteCasePopupIcon {
    color: var(--new-primary-color);
    height: 22px;
    width: 22px;
}

.incompleteCasePopupMetaText {
    margin: 0px;
    color: var(--new-text-color);
}

.incompleteCasePopupHeader {
    margin: 10px 0px;
    margin-bottom: 5px;
    color: var(--new-text-color);
}

.incompleteCasePopupText {
    margin-top: 7px;
    font-size: 14px;
    color: var(--new-off-text-color)
}

.incompleteCasePopupOptionsWrapper {
    width: calc(100% - 20px);
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    max-height: 160px;
    overflow-y: auto;
}

.incompleteCasePopupOptionContainer {
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: var(--new-text-color);
    padding: 10px;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.incompleteCasePopupOptionSelected {
    background-color: var(--new-primary-color);
    color: white;
}

.incompleteCasePopupOptionContainer:nth-last-child(1) {
    margin-bottom: 0px;
}

.incompleteCasePopupOptionText {
    margin: 0px;
}

.incompleteCasePopupButtonWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 10px;
}

.incompleteCasePopupLoadingWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
}

.incompleteCasePopupButton {
    height: 40px;
    background-color: var(--new-secondary-background-color);
    color: var(--new-primary-color);
    border-radius: 3px;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition-duration: 150ms;
}

.incompleteCasePopupButtonDisabled {
    background-color: var(--new-primary-background-color);
    color: var(--new-secondary-off-text-color);
    box-shadow: none;
    cursor: default;
    pointer-events: none;
}

.incompleteCasePopupButtonActive {
    color: white;
    background-color: var(--new-primary-color);
}
