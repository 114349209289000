

.accountSettingsPageContainer {
    width: 100%;
    background-color: var(--new-primary-background-color);
    padding-bottom: 50px;
    transition-duration: 250ms;
    padding-bottom: 50px;
    min-height: calc(100% - 50px);
}

.accountSettingsNavbar {
    height: 45px;
    width: calc(100% - 30px);
    background-color: var(--new-tertiary-background-color);
    /* background-color: rgba(0, 0, 0, 0); */
    display: grid;
    grid-template-columns: 1fr 150px 150px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    column-gap: 10px;
    padding: 10px 15px;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    transition-duration: 250ms;
}

.accountSettingsLogo {
    height: 35px;
}

.accountSettingsNavButton {
    height: 35px;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    justify-items: center;
    color: var(--new-text-color) !important;
    transition-duration: 250ms;
    background-color: var(--new-off-text-color);
}

.accountSettingsMobileNavButton {
    display: none;
}

@media(max-width: 600px) {
    .accountSettingsNavbar {
        grid-template-columns: 1fr 42px 42px;
    }

    .accountSettingsNavButton {
        display: none;
    }

    .accountSettingsMobileNavButton {
        height: 35px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        padding: 0px;
        color: var(--new-primary-color) !important;
    }
}

.accountSettingsNavButtonIcon {
    height: 20px;
    width: 20px;
    color: var(--new-primary-color);
}

.accountSettingsBodyWrapper {
    max-width: 600px;
    min-height: calc(100% - 150px);
    margin: auto;
    margin-top: 25px;
}

.accountSettingsBackButton {
    height: 40px;
    width: 200px;
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    justify-items: center;
    margin-bottom: 25px;
}

.accountSettingsBackArrowIcon {
    color: white;
    font-size: 18px;
}

.accountSettingsSuccessMessageContainer {
    height: 40px;
    display: grid;
    align-items: center;
    justify-items: center;
    margin-top: 10px;
    padding: 10px;
    background-color: var(--new-secondary-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition-duration: 250ms;
    position: relative;
}

.accountSettingsSuccessMessageText {
    margin: 0px;
    color: white;
    transition-duration: 250ms;
    font-size: 16px;
    font-weight: bold;
}

.accountSettingsSuccessCloseButton {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: 0px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 20px;
}

.accountSettingsSuccessCloseButton::before, .accountSettingsSuccessCloseButton::after {
    position: absolute;
    content: "";
    height: 15px;
    width: 2px;
    border-radius: 2px;
    background-color: var(--new-secondary-text-color);
  }
  
  .accountSettingsSuccessCloseButton::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .accountSettingsSuccessCloseButton::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  .accountSettingsSuccessCloseButton:hover {
    background-color: rgba(0, 0, 0, 0);
  }

.accountSettingsTitleContainer {
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: 10px;
    height: 40px;
    align-items: center;
    padding: 10px;
    transition-duration: 250ms;
}

@media(max-width: 600px) {
    .accountSettingsTitleContainer {
        margin: 0px 10px;
        margin-top: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        row-gap: 10px;
        height: fit-content;
    }

    .accountSettingsBackButton {
        margin-left: 10px;
    }
}

.accountSettingsTitle {
    margin: 0px;
    font-size: 24px;
    color: var(--new-primary-color);
    transition-duration: 250ms;
}

.accountSettingsTitleButton {
    color: white;
    background-color: var(--new-primary-color);
    border: 0px;
    border-radius: 3px;
    height: 35px;
    width: 150px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition-duration: 250ms;
}

.accountSettingsProfileContainer {
    background-color: var(--new-secondary-background-color);
    transition-duration: 250ms;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(6, 1fr);
    row-gap: 20px;
    margin-top: 10px;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media(max-width: 600px) {
    .accountSettingsProfileContainer {
        margin: 0px 10px;
        margin-top: 10px;
    }
}

.accountSettinsProfileDetailsHeader {
    grid-column-start: 1;
    grid-column-end: 3;
}

.accountSettingsCardTitle {
    margin: 0px;
    color: var(--new-case-text-color);
    transition-duration: 250ms;
}

.accountSettingsProfileProperty {
    margin: 0px;
    color: var(--new-case-text-color);
    transition-duration: 250ms;
}

.accountSettingsProfileValue {
    justify-self: end;
    color: var(--new-off-text-color);
    transition-duration: 250ms;
    margin: 0px;
}

@media(max-width: 600px) {
    .accountSettingsProfileContainer {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(12, auto);
        row-gap: 0px;
    }

    .accountSettinsProfileDetailsHeader {
        grid-column-end: 2;
        margin-bottom: 15px;
    }

    .accountSettingsProfileProperty {
        font-size: 14px;
        color: var(--new-off-text-color);
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .accountSettingsProfileValue {
        justify-self: start;
        font-size: 18px;
        color: var(--new-text-color);
        margin-bottom: 10px;
        font-weight: 400;
    }
}

.accountSettingsCardColumn1, .accountSettingsCardColumn2 {
    width: calc(50% - 5px);
    display: inline-block;
    vertical-align: top;
}

.accountSettingsCardColumn1 {
    margin-right: 10px;
}

.accountSettingsCard {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition-duration: 250ms;
    padding: 15px;
    margin-top: 10px;
    position: relative;
}

@media(max-width: 600px) {
    .accountSettingsCardColumn1, .accountSettingsCardColumn2 {
        margin: 0px 10px;
        width: calc(100% - 20px);
    }

    .accountSettingsBigCard {
        margin: 0px 10px;
        margin-top: 10px;
    }
}

.accountSettingsToggleTitle {
    color: var(--new-text-color);
    transition-duration: 250ms;
}

.accountSettingsToggleDescription {
    font-size: 12px;
    margin-top: 10px;
    color: var(--new-off-text-color);
    transition-duration: 250ms;
}

@media(max-width: 600px) {
    .accountSettingsToggleDescription {
        margin-top: 5px;
    }
}

.accountSettingsFaqQuestion {
    font-size: 20px;
    color: var(--new-text-color);
    margin: 0px;
}

.accountSettingsFaqAnswer {
    font-size: 14px;
    color: var(--new-text-color);
    margin: 0px;
    margin-top: 10px;
    white-space: pre-wrap;
}

.accountSettingsToggle {

}

.accountSettingsDropdown {
    height: 40px;
    width: 100%;
    background-color: var(--new-secondary-background-color);
    transition-duration: 250ms;
    cursor: pointer;
    color: var(--new-case-text-color);
    border: 0px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 0px 5px;
    /* -webkit-appearance: none; */
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 480px) {
    .accountSettingsDropdown {
        border: 1px solid var(--new-primary-color);
    }
}

.accountSettingsButton {
    height: 40px;
    width: 100%;
    border-radius: 3px;
    border: 0px;
    background-color: var(--new-primary-color);
    transition-duration: 250ms;
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.accountSettingsCancelButton {
    height: 40px;
    width: 100%;
    border-radius: 3px;
    border: 0px;
    transition-duration: 250ms;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.accountSettingsThemeBreakLine {
    height: 1px;
    background-color: var(--new-off-text-color);
    transition-duration: 250ms;
    width: 100%;
    margin-top: 5px;
}

.accountSettingsThemeWrapper {
    width: calc(50% - 5px);
    display: inline-block;
    vertical-align: bottom;
    margin-top: 15px;
}

.accountSettingsThemeWrapper:nth-child(even) {
    margin-right: 10px;
}

@media(max-width: 600px) {
    .accountSettingsThemeWrapper {
        /* margin: 0px 10px; */
        margin-top: 10px;
        width: calc(100% - 0px);
    }
}

.accountSettingsThemeTitle {
    margin: 0px;
    font-size: 18px;
    color: var(--new-case-text-color);
    transition-duration: 250ms;
}

.accountSettingsThemeDescription {
    font-size: 14px;
    color: var(--new-off-text-color);
    transition-duration: 250ms;
    margin: 10px 0px;
    margin-top: 5px;
}

.accountSettingsThemeColorSquaresContainer {
    width: 100%;
}

.accountSettingsThemeColorSquare {
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-right: 10px;
}

.accountSettingsThemeButton {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: 0px;
    border-radius: 3px;
    background-color: var(--new-primary-color);
    transition-duration: 250ms;
    color: white;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.accountSettingsThemeButtonActive {
    border: 1px solid var(--new-primary-color);
    color: var(--new-primary-color);
    background-color: var(--new-secondary-background-color);
    transition-duration: 250ms;
    box-shadow: none;
    cursor: default;
}

.accountSettingsThemeButtonDisabled {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    transition-duration: 0ms !important;
}

.accountSettingsThemeSpinnerContainer {
    margin-top: 10px;
    height: 40px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}

.accountSettingsOrderedList {
    display: grid;
    list-style-type: none;
    grid-template-columns: 20px 1fr;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    row-gap: 10px;
    width: calc(100% - 10px);
    padding: 0px;
    column-gap: 10px;
}

.accountSettingsOrderedListNumber {
    font-size: 24px;
    margin: 0px;
    color: var(--new-primary-color);
    transition-duration: 250ms;
    font-weight: bold;
    justify-self: center;
}

.accountSettingsOrderedListItem {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: var(--new-case-text-color);
    transition-duration: 250ms;
    font-weight: 500;
    font-size: 14px;
    width: calc(100% - 10px);
    height: fit-content;
    vertical-align: top;
    position: relative;
}

.accountSettingsOrderedListItem > p {
    height: fit-content;
    margin: 0px;
}

.accountSettingsUpgradeButton {
    background-color: var(--new-secondary-color);
    transition-duration: 250ms;
}

.accountSettingsButtonWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.hideCasesButton {
    grid-column: 2;
}

@media(max-width: 600px) {
    .accountSettingsButtonWrapper {
        margin-top: 10px;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        row-gap: 10px;
        column-gap: 0px;
    }

    .hideCasesButton {
        grid-column: 1;
    }
}

.accountSettingsHeaderCard {
    background-color: var(--new-secondary-background-color);
    transition-duration: 250ms;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 10px 0px;
}

.accountSettingsHeaderCardText {
    margin: 0px;
    font-size: 24px;
    color: var(--new-text-color);
}

@media(max-width: 500px) {
    .accountSettingsHeaderCard {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 5px;
    }
}

.accountSettingsHiddenCaseContainer {
    background-color: var(--new-secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding: 10px;
    column-gap: 10px;
    margin-bottom: 10px;
}

.accountSettingsHiddenCaseId {
    margin: 0px;
    font-size: 32px;
    color: var(--new-primary-color);
}

.accountSettingsHiddenCaseCategory {
    margin: 0px;
    color: var(--new-text-color);
}

.accountSettingsHiddenCaseButton, .accountSettingsHiddenAllCasesButton {
    background-color: var(--new-primary-color);
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 0px;
    height: 35px;
    width: 150px;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    justify-items: center;
    cursor: pointer;
}

.accountSettingsHiddenAllCasesButton {
    width: 200px;
}

.accountSettingsHiddenCaseIcon {
    height: 20px;
    width: 20px;
}

.accountSettingsHiddenCasesWrapper {
    padding: 0px 10px;
}

.accountSettingsHiddenCaseSpinnerContainer, .accountSettingsHiddenCaseSpinnerContainerHeader {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    height: 35px;
    width: 150px;
}

.accountSettingsHiddenCaseSpinnerContainerHeader {
    width: 200px;
}

.accountSettingsNoHiddenCases {
    margin: 0px;
    background-color: var(--new-secondary-background-color);
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
}

.accountSettingsNoHiddenCasesText {
    margin: 0px;
    font-size: 16px;
    color: var(--new-text-color);
    text-align: center;
}

.accountSettingsCardLoaderOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    display: grid;
    align-items: center;
    justify-items: center;
}

.accountSettingsCardButtonLoaderContainer {
    display: grid;
    align-items: center;
    justify-items: center;
}

.changePasswordErrorText {
    color: red;
    margin-top: 5px;
}