

.tagsPopupPageContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.tagsPopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.tagPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    max-width: 550px;
    width: 90%;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    transform: translate(-50%, -50%);
}

.tagPopupTitle {
    color: var(--new-text-color);
    margin: 0px;
    font-size: 24px;
    margin-top: 5px;
}

.tagPopupDescription {
    color: var(--new-off-text-color);
    margin: 10px 0px;
    margin-top: 5px;
    font-size: 14px;
}

.tagPopupInput {
    font-size: 14px;
    height: 40px;
    width: calc(100% - 10px);
    padding: 0px;
    padding-left: 10px;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    border: 0px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    color: var(--new-tertiary-off-text-color);
    cursor: initial;
}

.tagPopupInput::placeholder {
    color: var(--new-tertiary-off-text-color);
    opacity: 0.6;
}

@media(max-width: 525px) {
    .tagPopupInput {
        font-size: 16px;
    }
}

.tagPopupTagWrapper {
    background-color: var(--new-primary-background-color);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 10px;
    padding-bottom: 0px;
    padding-right: 0px;
    min-height: 100px;
    overflow-y: auto;
    height: 200px;
}

.tagPopupTagContainer {
    background-color: var(--new-primary-color);
    display: inline-grid;
    grid-template-columns: auto 1fr;
    border-radius: 3px;
    height: fit-content;
    width: fit-content;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: top;
}

.tagPopupContainerSelected {
    background-color: var(--new-secondary-color);
}

.tagPopupTagButton {
    color: white;
    background-color: rgba(0, 0, 0, 0);
    height: 10px;
    width: 20px;
    padding-left: 5px;
    position: relative;
    border: 0px;
    align-self: center;
    justify-self: center;
    cursor: pointer;
}

.tagPopupTagButton::before, .tagPopupTagButton::after {
    content: "";
    position: absolute;
    background-color: white;
    top: 50%;
    left: calc(50% + 5px);
    height: 12px;
    width: 2px;
    border-radius: 2px;
}

.tagPopupTagButton::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.tagPopupTagButton::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.tagPopupTagText {
    margin: 0px;
    color: white;
    padding: 6px 10px;
    font-size: 14px;
}

.tagPopupFooterWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 10px;
}

.tagPopupCountWrapper {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}

.tagPopupCountText {
    margin: 0px;
    color: var(--new-text-color);
}


.tagPopupButton {
    height: 40px;
    width: 100%;
}
