

.loadingPopupWrapper {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
}

.loadingPopupBackdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 15;
}

.loadingPopupContainer {
    position: absolute;
    height: 100px;
    padding: 25px 0px;
    padding-top: 35px;
    width: 250px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    border-radius: 5px;
    display: grid;
    align-items: center;
    justify-items: center;
}

.toggleGradingContainer {
    position: fixed;
    bottom: 10px;
    left: 10px;
    padding: 10px;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.toggleGradingButton {
    height: 40px;
    width: 200px;
    color: white;
    background-color: var(--new-primary-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border: 0px;
    cursor: pointer;
}

.printReceiptContainer {

}

.printReceiptButtonContainer {
    position: sticky;
    bottom: 0;
    background-color: #3E3E3E;
    border-top: 1px solid #bbbbbb;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    padding: 10px;
    width: calc(100% - 20px);
}

.printReceiptButton {
    height: 40px;
    width: 100%;
    max-width: 300px;
    justify-self: start;
}

.printReceiptButton:nth-child(1) {
    justify-self: end;
}

@media print {
    .printReceiptButtonContainer {
        display:none;
    }
}