
.authenticationErrorPageContainer {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
}

.authenticationErrorBackdrop {
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.55);
	z-index: 1;
}

.authenticationErrorContainer {
    border: 7px solid var(--secondary-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50vh;
    min-height: 200px;
    width: calc(100% - 25px);
    max-width: 750px;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-color);
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.45);
    z-index: 3;
}