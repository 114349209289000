
body {
    background-color: white;
}

.loginPageWrapper {
    height: 100%;
    width: 100%;
    background-color: #0B335D;
}

.loginLandingPage {
    height: fit-content;
    display: grid;
    align-items: center;
    justify-items: center;
    position: relative;
    top: calc(50% - 46px);
    transform: translateY(-50%);
}

.loginLogo {
    height: 40px;
    /* position: relative; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    margin-top: 25px;
    margin-bottom: 30px;
}

.loginCardContainer {
    display: grid;
    grid-template-columns: auto auto;
    height: fit-content;
    width: fit-content;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    overflow: hidden;
    background-color: white;
    transition-duration: 250ms;
}

@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance: none) {
    .loginCardContainer {
        max-height: 580px;
    }
  }
}

.loginImage {
    height: 100%;
    width: 400px;
    object-position: right;
    object-fit: cover;
}

.loginContentWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 350px;
    padding: 50px;
    transition-duration: 250ms;
    position: relative;
}

.loginHeader {
    margin: 0px;
    padding: 0px;
    font-size: 24px;
    padding-left: 10px;
    margin-bottom: 15px;
    color: #0B335D;
}

@media(max-width: 850px) {
    .loginCardContainer {
        grid-template-columns: auto;
        grid-template-rows: auto;
        width: 95%;
        max-width: 350px;
    }

    .loginImage {
        width: 100%;
        max-height: 200px;
        display: none;
    }

    .loginContentWrapper {
        padding: 0px;
        width: 100%;
    }

    .loginHeader {
        margin: 15px 0px;
    }
}

.loginContainer {
    width: 100%;
    transition-duration: 250ms;
}

.termsOfServiceContainer {
    max-height: 400px;
    height: 90%;
    padding-bottom: 15px;
}

.loginTermsOfServiceWrapper {
    max-height: 270px;
    overflow-y: auto;
    padding: 0px 10px;
}

.loginContainer > p > strong {
    font-weight: 600;
}

.loginLogoText {
    font-size: 26px;
    color: var(--new-primary-color);
    text-align: center;
}

.loginLabel {
    display: block;
    margin: 5px 10px;
    font-size: 14px;
    color: black;
}

.loginInput, .loginSelect {
    display: block;
    margin: 0px 10px;
    height: 40px;
    padding: 0px;
    padding-left: 10px;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    width: calc(100% - 30px);
    /* font-family: apparat; */
    font-size: 14px;
    background-color: #f4f4f4;
    border-radius: 2px;
    color: black;
    margin-bottom: 10px;
}

input::selection, textarea::selection {
    background-color: #0B335D;
    color: white;
}

.loginSelect {
    padding-left: 5px;
    width: calc(100% - 20px);
}

@media(max-width: 600px) {
    .loginInput {
        font-size: 16px;
    }
}

.forgotPasswordText {
    font-size: 12px;
    margin-left: 10px;
    color: #0B335D;
    text-decoration: underline;
    cursor: pointer;
}

.loginButtonWrapper {
    display: grid;
    margin: 10px;
    margin-top: 15px;
    height: 40px;
    width: calc(100% - 20px);
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.loginButton {
    font-weight: 500;
    border-radius: 3px;
    border: 0px;
    font-size: 13px;
    background-color: white;
    color: black;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.loginPrimaryButton {
    background-color: #0B335D;
    color: white;
}

.loginOffline {
    height: 40px;
    width: calc(100% - 20px);
    margin: 10px;
    border: 1px solid #0B335D;
    color: #0B335D;
    background-color: #ffffff;
    border-radius: 2px;
}

@media(max-width: 600px) {
    .loginButton {
        font-size: 12px;
    }
}

.loginButtonLoaderWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 40px;
    width: calc(100% - 20px);
    margin: 10px;
    margin-top: 15px;
}

.termsOfServiceText {
    margin: 10px;
    max-height: 215px;
    overflow: auto;
    color: black;
}

.termsOfServiceBackButton {
    margin: 10px;
    height: 40px;
    width: calc(100% - 20px);
}

.loginTermsOfServiceText {
    display: block;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    color: black;
    text-decoration: underline;
    cursor: pointer;
}

.loginMessageFooter {
    background-color: #F79D23;
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 5px 20px;
    max-width: 810px;
}

.loginErrorText, .loginSuccessText {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    text-align: center;
}

.loginErrorText {
    color: red;
}

.loginSuccessText {
    color: green;
}

.loginMessageFooterText {
    color: white;
    font-weight: bold;
}

@media(max-width: 850px) {
    .loginMessageFooter {
        grid-column-end: 2;
    }
}