

.newGradingPageWrapper {
    background-color: var(--new-primary-background-color);
    padding-bottom: 40px;
    min-height: calc(100% - 40px);
    position: relative;
}

.newGradingNavbar {
    height: 45px;
    width: calc(100% - 30px);
    background-color: var(--new-tertiary-background-color);
    display: grid;
    grid-template-columns: 1fr 150px 150px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    column-gap: 10px;
    padding: 10px 15px;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
}

.newGradingLogo {
    height: 35px;
}

@media(max-width: 750px) {
    .newGradingPageWrapper {
        padding-bottom: 10px;
        min-height: calc(100% - 10px);
    }
}

.newGradingLoaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    display: grid;
    align-items: center;
    justify-items: center;
}

.newGradingLoaderText {
    color: var(--new-text-color);
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

/* End Navbar */
/* Begin Back Button Wrapper */

.newGradingBackWrapper {
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: 10px;
    height: 60px;
    align-items: center;
    margin: 20px 40px;
}

.newGradingBackButton {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    justify-items: center;
    height: 40px;
    width: 200px;
}

.newGradingBackIcon {
    color: white;
    font-size: 16px;
}

.newGradingCheckboxContainer {
    height: 40px;
    display: grid;
    width: 175px;
    column-gap: 10px;
    align-items: center;
    justify-items: center;
    background-color: var(--new-secondary-background-color);
    padding: 5px 10px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: relative;
}

.highYieldLoadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: grid;
    align-items: center;
    justify-items: center;
}

.highYieldMoreInfoButton {
    color: var(--new-primary-color);
    font-size: 20px;
    padding: 10px;
    padding-left: 0px;
    padding-right: 5px;
}

.newGradingCheckbox {
    height: 25px;
    width: 25px;
    background-color: var(--new-primary-background-color);
    border-radius: 3px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    align-items: center;
    justify-items: center;
}

.newGradingCheckboxIcon {
    color: var(--new-primary-color);
    font-size: 14px;
}

.newGradingCheckboxText {
    font-size: 16px;
    margin: 0px;
    color: var(--new-text-color);
}

@media(max-width: 750px) {
    .newGradingBackWrapper {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        row-gap: 10px;
        height: fit-content;
        margin: 10px;
    }

    .newGradingBackButton {
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .newGradingCheckboxContainer {
        width: calc(100% - 20px);
    }

    .newGradingCheckboxText {
        font-size: 14px;
    }
}

/* End Back Button Wrapper */
/* Begin Title Container */

.newGradingCaseTitleContainer {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 20px;
    padding: 15px;
    background-color: var(--new-secondary-background-color);
    margin: 5px 40px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.newGradingCaseNumberContainer {
    height: 60px;
    min-width: 40px;
    padding: 0px 10px;
    width: fit-content;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: var(--new-secondary-color);
    border-radius: 3px;
    align-self: center;
}

.newGradingCaseNumber {
    color: white;
    font-size: 36px;
    font-weight: 600;
    margin: 0px;
}

.newGradingCaseTitleWrapper {
    display: grid;
    grid-auto-flow: row;
    row-gap: 5px;
    align-self: center;
}

.newGradingCaseTitle {
    margin: 0px;
    font-weight: 600;
    color: var(--new-text-color);
}

.newGradingCaseCompletionDate {
    margin: 0px;
    font-weight: 500;
    color: var(--new-off-text-color);
    font-size: 16px;
}

.newGradingViewPreviousButton {
    height: 40px;
    align-self: center;
    padding: 0px 20px;
}

@media(max-width: 750px) {
    .newGradingCaseTitleContainer {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        row-gap: 10px;
        margin: 10px;
    }

    .newGradingCaseNumberContainer {
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .newGradingCaseTitle {
        font-size: 20px;
    }

    .newGradingCaseCompletionDate {
        font-size: 14px;
    }

    .newGradingViewPreviousButton {
        max-width: 200px;
        font-size: 12px;
    }
}

/* End Title Container */
/* Begin Details */

.newGradingCaseDetailsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    padding: 10px 40px;
    column-gap: 15px;
}

.newGradingCaseDetailContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;
    align-items: center;
    background-color: var(--new-secondary-background-color);
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.newGradingCaseDetailIconContainer {
    height: 60px;
    width: 60px;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: var(--new-primary-color);
    border-radius: 3px;
    grid-row-start: 1;
    grid-row-end: 3;
}

.newGradingCaseDetailIcon {
    color: white;
    font-size: 28px;
}

.newGradingCaseDetailTitle {
    margin: 0px;
    font-size: 12px;
    color: var(--new-off-text-color);
}

.newGradingCaseDetailValue {
    margin: 0px;
    font-size: 26px;
    color: var(--new-text-color);
}

@media(max-width: 750px) {
    .newGradingCaseDetailsWrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        row-gap: 10px;
        padding: 0px;
        margin: 10px;
    }

    .newGradingCaseDetailIconContainer {
        height: 40px;
        width: 40px;
    }

    .newGradingCaseDetailIcon {
        font-size: 20px;
    }

    .newGradingCaseDetailTitle {
        font-size: 10px;
    }

    .newGradingCaseDetailValue {
        font-size: 20px;
    }

}

/* End Details */
/* Begin Feedback */

.newGradingFeedbackTitleContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    margin: 5px 40px;
    padding: 15px;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.newGradingFeedbackTitle {
    margin: 0px;
    font-weight: 600;
    color: var(--new-text-color);
}

.newGradingFeedbackCheckboxContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
}

.newGradingFeedbackItemContainer {
    background-color: var(--new-secondary-background-color);
    margin: 15px 40px;
    margin-top: 5px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
}

.newGradingFeedbackItemTitle {
    margin: 0px;
    color: var(--new-text-color);
    font-weight: 600;
}

.newGradingFeedbackItemValue {
    margin: 0px;
    font-size: 14px;
    margin-top: 4px;
    font-weight: 500;
    color: var(--new-off-text-color);
}

.newGradingFeedbackItemContentWrapper {
    background-color: var(--new-primary-background-color);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin-top: 20px;
    border-radius: 3px;
}

.newGradingFeedbackAnswerWrapper {
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
}

.newGradingFeedbackAnswerWrapper:nth-last-child(1) {
    margin-bottom: 0px;
}

.newGradingFeedbackAnswerIncorrect {
    background-color: #FFDDDD;
    color: #411414;
}

.newGradingFeedbackAnswerCorrect {
    background-color: #E2FFDD;
    color: #175317;
}

.newGradingFeedbackAnswerNeutral {
    background-color: #d1ecf1;
    color: #203438;
}

.newGradingFeedbackAnswerTitle {
    margin: 0px;
    font-size: 18px;
    color: inherit;
}

.newGradingFeedbackAnswerItemList {
    margin: 0px;
}

.newGradingFeedbackAnswerItem {
    margin-bottom: 3px;
}

.newGradingFeedbackReason {
    margin: 0px;
    margin-top: 15px;
}

@media(max-width: 750px) {
    .newGradingFeedbackTitleContainer {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        row-gap: 10px;
        margin: 10px;
    }

    .newGradingFeedbackTitle {
        font-size: 20px;
    }

    .newGradingFeedbackCheckboxContainer {

    }

    .newGradingFeedbackItemContainer {
        margin: 10px;
    }

    .newGradingFeedbackItemTitle {
        font-size: 20px;
    }

    .newGradingFeedbackItemValue {
        font-size: 14px;
    }

    .newGradingFeedbackItemContentWrapper {
        padding: 10px;
    }

    .newGradingFeedbackAnswerWrapper {
        padding: 10px;
    }

    .newGradingFeedbackAnswerTitle {
        font-size: 16px;
    }

    .newGradingFeedbackAnswerItemList {
        margin: 0px;
        padding-left: 25px;
    }

    .newGradingFeedbackAnswerItem {
    }

    .newGradingFeedbackReason {
        font-size: 13px;
    }
}

/* End Feedback */
/* Begin CaseSummary */

.newGradingCaseSummaryContainer {
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin: 5px 40px;
    padding: 20px;
}

.newGradingCaseSummaryTitle {
    margin: 0px;
    font-weight: 600;
    color: var(--new-text-color);
}

.newGradingCaseSummaryText {
    margin: 0px;
    margin-top: 10px;
    color: var(--new-text-color);
	white-space: pre-wrap;
}

@media(max-width: 750px) {
    .newGradingCaseSummaryContainer {
        padding: 20px;
        margin: 10px;
    }

    .newGradingCaseSummaryTitle {
        font-size: 22px;
    }

    .newGradingCaseSummaryText {
        font-size: 14px;
    }
}

/* End Case Summary */