

.unauthenticatedPageContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
}

.unauthenticatedBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.65);
}

.unauthenticatedContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    height: fit-content;
    width: calc(100% - 50px);
    max-width: 450px;
    padding: 15px;
    z-index: 2;
    background-color: var(--new-secondary-background-color);
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
}

.unauthenticatedHeader {
    margin: 0px;
    font-size: 24px;
    color: var(--new-text-color);
}

.unauthenticatedDescription {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 15px;
    color: var(--new-text-color);
    opacity: 0.8;
}

.unauthenticatedListHeader {
    margin: 0px;
    font-size: 18px;
}

.unauthenticatedList {
    padding-left: 20px;
    margin-top: 5px;
}

.unauthenticatedListItem {
    font-size: 15px;
    margin: 7px 0px;
}

.unauthenticatedButton {
    width: 100%;
    height: 40px;
}
