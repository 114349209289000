.network-lag {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.network-lag-popup {
	height: 50vh;
	width: 50vw;
	background: white;
	border: 1px solid #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
}

label {
	cursor: pointer;
}

.simulation {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	background-color: var(--primary-color);
}

.time-title {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
}

.simulation-top-bar {
	display: flex;
	position: relative;
	flex-direction: row;
	padding: 10px 0px;
}

.simulation-end-button {
	display: flex;
	align-items: center;
	margin-right: 0.5em;
}

.simulation-options {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 1em;
}

.simulation-options-group {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.simulation-option {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	min-height: 10vh;
	width: 50%;
	background: var(--gradient);
	border-radius: 5px;
	border: 1px solid #ccc;
	margin: 1px;
	cursor: pointer;
}

.simulation-option:hover {
	background: var(--reverse-gradient);
}

.simulation-option-selected {
	cursor: initial;
	background: none;
	background-color: var(--selected-button-color);
}

.simulation-option-selected:hover {
	background: none;
	background-color: var(--selected-button-color);
}

.simulation-option-main {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-around;
	margin-left: 1em;
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
	margin: 0px;
}

.physical-options {
	width: 100%;
	height: 100%;
}

.options-box {
	border: 1px solid #ccc;
	display: flex;
	flex-direction: column;
	width: 50vw;
	margin-left: 1em;
	margin-right: 1em;
	margin-bottom: 1em;
	border: 7px solid var(--secondary-color);
	border-radius: 4px;
}

.options-box-label {
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
}

.options-box-checkbox {
	padding: 1em;
	display: flex;
	flex-direction: row;
	width: 12em;
}

.options-box-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.physical-options-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 1em;
}

.information-popup {
	position: absolute;
	border: 7px solid var(--secondary-color);
	height: 90%;
	max-height: 400px;
	width: 90%;
	max-width: 750px;
	display: flex;
	flex-direction: column;
	background-color: var(--primary-color);
	overflow-y: auto;
	border-radius: 4px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.45);
	z-index: 4;
}

.popup-backdrop {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.55);
	z-index: 3;
}

.popup-blocker {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	overflow-y: auto;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.popup-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: bold;
	margin: 0px;
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
	background-color: var(--secondary-color);
	padding-left: 1em;
	padding-top: 0.2em;
	padding-bottom: 0.4em;
	margin-bottom: 1em;
}

.popup-content {
	padding-bottom: 1em;
	padding-left: 1em;
	padding-right: 1em;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;
	color: var(--primary-font-color);
}

.popup-history {
	white-space: pre-wrap;
}

.popup-vital {
	margin: 0px;
	font-size: var(--primary-font-size);
}

.popup-vital-text {
	margin: 0px;
	margin-left: 5px;
	font-size: var(--primary-font-size);
}

.popup-vital-line {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 500px;
	margin: auto;
}

.popup-element {
	margin-bottom: 1em;
	width: 100%;
	color: var(--primary-font-color);
	font-size: var(--primary-font-size);
}

.popup-error-text {
	
}

.centered {
	display: flex;
	justify-content: center;
}

.popup-footer {
	display: flex;
	justify-content: center;
	padding: 1em;
}

.simulation-center-content {
	width: 100%;
	/* max-height: calc(100vh - 195px); */
	/* safari testing*/
	flex: 1;
	margin-bottom: 20px;
}

.simulation-time-bar {
	display: flex;
	flex-direction: row;
	width: 98vw;
	background: var(--accent-color);
	height: 3vh;
	justify-content: space-between;
	align-items: center;
	padding-left: 1vw;
	padding-right: 1vw;
}

.physical-result-header {
	font-weight: bold;
}

.review-orders {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.review-orders-content {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
}

.review-orders-chart-tabs {
	padding-left: 1em;
	padding-right: 1em;
	display: flex;
	flex-direction: row;
	font-weight: bold;
}

.review-orders-chart-tabs-group {
	display: flex;
	flex-direction: row;
}

.review-orders-chart-tab {
	border: 1px solid #ccc;
	display: flex;
	padding-left: 1em;
	padding-right: 1em;
	flex-direction: row;
	justify-content: center;
	border-radius: 5px;
	margin: 1px;
	cursor: pointer;
	color: var(--header-font-color);
	font-size: var(--primary-font-size);
	background: var(--gradient);
}

.review-orders-chart-tab:hover {
	background: var(--reverse-gradient);
}

.review-orders-chart-tab.true {
	background: var(--primary-color);
	border-bottom: none;
	border-radius: 5px 5px 0 0;
}

.order-input {
	resize: none;
	width: 100%;
	height: 100%;
	flex: 1;
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
	background-color: var(--primary-color);
	position: relative;
	top: 1px;
}

.reevaluate-popup {
	height: 65%;
	max-height: 425px;
}

.reevaluate-box-calendar {
	display: flex;
	flex-direction: column;
	margin-left: 1em;
	margin-right: .5em;
	border: 5px solid var(--secondary-color);
	border-radius: 5px;
	background-color: var(--accent-color);
	overflow-y: auto;
	min-height: 200px;
}

.reevaluate-box-case {
	display: flex;
	flex-direction: column;
	margin-left: .5em;
	margin-right: 1em;
	border: 5px solid var(--secondary-color);
	border-radius: 5px;
	background-color: var(--accent-color);
	flex-grow: 2;
}

.reevaluate-case {
	display: grid;
	grid-template-columns: auto 1fr;
	row-gap: 10px;
	margin-left: 1em;
	margin-right: 1em;
	height: fit-content;
	overflow-y: auto;
	column-gap: 15px;
}

.reevaluate-case-modes {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.reevaluate-mode-checkbox {
	flex-direction: row;
	margin-bottom: 1em;
	display: grid;
	align-items: center;
	grid-template-columns: 20px auto;
}

.reevaluate-mode-label {
	color: var(--primary-font-color);
}

.reevaluate-case-amounts {
	display: flex;
	flex-direction: row;
}

.reevaluate-case-time {
	display: flex;
	flex-direction: column;
	grid-column: 2;
	grid-row-start: 1;
	grid-row-end: 3;
}

.reevaluate-case-amount {
	display: flex;
	flex-direction: column;
	width: 5em;
	margin: 1px;
}

.reevaluate-case-text {
	margin: 0px;
	color: var(--primary-font-color);
}

.reevaluate-case-input {
	background-color: var(--primary-color);
	color: var(--primary-font-color);
	border-color: var(--secondary-color);
}

.calendar {
	display: flex;
	flex-direction: column;
	margin-left: 1em;
	margin-right: 1em;
	margin-bottom: 1em;
	border: 1px solid var(--secondary-color);
	flex: 1;
}

.calendar-headers {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	min-height: 22px;
	background: var(--gradient);
}

.calendar-header {
	font-weight: bold;
	display: flex;
	justify-content: center;
	border-right: 1px solid var(--secondary-color);
	width: 100%;
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
	margin: 2px 0px;
}

.popup-reevaluate-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	overflow-y: auto;
}

.reevaluate-error-text {
	height: 2em;
	margin: 0px;
	text-align: center;
	display: grid;
	align-items: center;
	font-weight: bold;
	color: red;
}

.calendar-day {
	display: flex;
	width: 100%;
	cursor: pointer;
	font-size: var(--primary-font-size);
	border-right: 1px solid var(--secondary-color);
	color: var(--primary-font-color);
}

.calendar-day.true {
	background: #0095C8;
}

.calendar-days-row {
	background-color: var(--primary-color);
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	width: 100%;
}

.calendar-days-row:nth-child(even) {
	background-color: var(--accent-color);
}

.simulation-time {
	display: flex;
	flex-direction: row;
	margin: 0px;
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
}

.white-space {
	white-space: pre;
}

.confirm-choice {
	display: flex;
	flex-direction: row;
}

.confirm-choice-name {
	width: 10em;
}

.qualifier-element {
	width: 100%;
	border: 7px solid var(--secondary-color);
	border-radius: 4px;
	margin-bottom: 1em;
	padding-bottom: 1em;
	flex: 1;
}

.qualifier-checkbox {
	display: flex;
	flex-direction: row;
	padding-left: 2em;
	white-space: pre;
	color: var(--primary-font-color);
	font-size: var(--primary-font-size);
}

.qualifier-input {
	background-color: var(--accent-color);
	color: var(--primary-font-color);
	border-color: var(--secondary-color);
}

.qualifier-select {
	background-color: var(--accent-color);
	color: var(--primary-font-color);
	margin-left: 5px;
}

.location-column {
	display: grid;
	grid-template-rows: auto 1fr 1fr 1fr;
	flex: 1;
	overflow-y: auto;
	border-radius: 4px;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.45);
	border: 7px solid var(--secondary-color);
	background-color: var(--secondary-color);
}

.left {
	margin-left: 1em;
	margin-right: .5em;
}

.right {
	margin-left: .5em;
	margin-right: 1em;
}

.location-button {
	display: grid;
	justify-items: center;
	align-items: center;
	border: 1px solid var(--primary-font-color);
	padding: 1em;
	margin: 0 0.5em 0.5em 0.5em;
	cursor: pointer;
	background-color: var(--primary-color);
	color: var(--primary-font-color);
	font-size: var(--primary-font-size);
	border-radius: 7px;
}

.location-button-text {
	text-align: center;
}

.location-button-image {
	max-width: 80px;
	width: 80%;
}

.highlight {
	background: linear-gradient(to bottom, #b1daed 0%, #8fc3d9 100%);
	border: 1px solid #0095C8;
	border-radius: 2px;
}

.order-review-headers {
	display: flex;
	flex-direction: row;
	background-color: var(--primary-color);
	border-bottom: 1px solid var(--off-color);
	width: 100%;
	font-weight: bold;
}

.order-review-headers-actual {
	display: flex;
	flex-direction: row;
	background: var(--primary-color);
	border-bottom: 1px solid var(--off-color);
}

.header {
	justify-content: center;
	/* safari testing */
	align-items: center;
	height: 1.5em;
}

.review-header {
	justify-content: center;
	/* safari testing */
	align-items: center;
	height: 1.5em;
	border-right: 1px solid var(--off-color);
}

.order-review-column {
	/* width: 10em; */
	display: flex;
	flex-direction: row;
	flex: 1;
	height: auto;
	/*border-left: 1px solid #ccc;*/
	padding-left: 5px;
	padding-right: 5px;
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
}

.log-review-column {
	/* width: 10em; */
	display: flex;
	flex-direction: row;
	flex: 1;
	height: auto;
	justify-self: center;
	/*border-left: 1px solid #ccc;*/
	padding-left: 5px;
	padding-right: 5px;
	font-size: var(--primary-font-size);
	color: var(--primary-font-color);
}

.order-review-row {
	width: 100%;
	/* display: flex; */
	display: grid;
	grid-template-columns: repeat(5, 20%);
	flex-direction: row;
	cursor: pointer;
	padding: 2px 0px;
	align-items: center;
	background-color: var(--accent-color);
}

.log-review-row {
	width: 100%;
	/* display: flex; */
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	flex-direction: row;
	cursor: pointer;
	padding: 2px 0px;
	align-items: center;
	background-color: var(--accent-color);
}

.log-review-row:nth-child(even) {
	background-color: var(--primary-color);
}

.log-review-row:hover {
	background-color: var(--hover-color);
}

.no-pointer {
	cursor: auto;
}

.review-orders-table-wrapper {
	display: grid;
	height: 100%;
	grid-template-rows: 1fr auto;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.65);
	margin: 0 1em;
	border-radius: 4px;
}

.order-review-table {
	display: flex;
	flex-direction: column;
	flex: 1;
	border-radius: 4px 4px 0px 0px;
	border: 7px solid var(--secondary-color);
	overflow-y: auto;
	/* max-height: calc(100vh - 275px); */
}

.order-review-table-header {
	margin: 0px;
	background-color: var(--secondary-color);
	color: var(--primary-font-color);
	font-size: var(--primary-font-size);
	font-weight: bold;
	padding-bottom: 10px;
}

.order-select-text {
	font-size: var(--primary-font-size);
	background-color: var(--accent-color);
}

.review-orders-footer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 1em;
	background-color: var(--secondary-color);
	border-radius: 0 0 4px 4px;
}

.review-orders-footer-end {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 1em;
	justify-content: space-around;
}

.order-review-row:nth-child(even) {
	background-color: var(--primary-color);
}

.order-review-row:hover {
	background-color: var(--hover-color);
}

.location-button.selected {
	background: #0095C8;
}

.location-button.current-location {
	opacity: .5;
	pointer-events: none;
}

.options-footer {
	display: flex;
	flex-direction: row;
	padding: 1em;
	justify-content: center;
	background-color: var(--secondary-color);
}

.options-footer-loading-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 1em;
	background-color: var(--secondary-color);
}

.simulation-button {
	padding: 3px 10px;
	border-radius: 5px;
	font-size: var(--secondary-font-size);
	color: var(--primary-font-color);
	border: 1px solid var(--border-color);
	background-color: var(--button-background-color);
	min-height: 24px;
}

.simulation-button:hover {
	background-color: var(--hover-color);
	border-color: var(--secondary-color);
}

.simulation-button.fade {
	opacity: 0.5;
	pointer-events: none;
}

.button-gap {
	margin-right: 1em;
}

.verification-buttons-left {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex: 1;
}

.verification-buttons-right {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex: 1;
}

.red {
	color: red;
}

.location-schedule-box {
	border: 7px solid var(--secondary-color);
	border-radius: 4px;
	margin-top: 1em;
	position: relative;
	left: 50%;
	transform: translate(-50%, -8px);
	width: 100%;
	max-width: 250px;
}

.location-popup {
	border: 7px solid var(--secondary-color);
	border-radius: 4px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.45);
	width: 90%;
	max-width: 700px;
	position: absolute;
	display: flex;
	flex-direction: column;
	background: var(--primary-color);
	overflow-y: auto;
	z-index: 4;
}

.bold {
	font-weight: bold;
}

.end-case-qualifiers {
	display: flex;
	flex-direction: row;
	flex: 1;
}

.end-case-qualifier {
	display: flex;
	flex-direction: column;
	flex: 1;
	border: 7px solid var(--secondary-color);
	border-radius: 4px;
	margin-right: 1em;
	margin-bottom: 2em;
}

.login-screen {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	position: relative;
	overflow: hidden;
}

.login-screen-test-background {
	background-color: rgba(253, 133, 153, 0.404);
}

.login-popup-message-container {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	width: 100%;
	max-width: 500px;
	background-color: #1c4670;
}

.login-popup-message {
	color: white;
	margin-left: 10px;
	margin-right: 10px;
}

.login-popup-close-button {
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	color: white;
	border: none;
	background-color: rgba(0, 0, 0, 0);
	cursor: pointer;
}

.login-legal-box {
	border: 1px solid #ccc;
	border-radius: 1em;
	display: grid;
	grid-template-rows: 50px auto 50px;
	flex-direction: column;
	width: 100%;
	height: 80vh;
	max-height: 400px;
	min-height: 200px;
	max-width: calc(330px + 2em);
	z-index: 3;
	background-color: white;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
}

.login-legal-header-container {
	display: grid;
	align-items: center;
	border-bottom: 1px solid rgb(200, 200, 200);
}

.login-legal-header {
	margin: 0px;
	margin-left: 0.5em;
}

.login-legal-body-container {
	overflow-y: auto;
}

.login-legal-text {
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.login-legal-footer-container {
	display: grid;
	align-items: center;
	padding: 0px 2em;
	border-top: 1px solid rgb(200, 200, 200);
}

.login-box {
	border: 1px solid #ccc;
	border-radius: 1em;
	display: flex;
	flex-direction: column;
	padding: 2em;
	width: 100%;
	max-width: 330px;
	z-index: 3;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
}

.login-box-element {
	width: 100%;
	margin-bottom: 0.75em;
}

.login-box-element.forgot {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	text-decoration: underline;
}

.login-box-element.demo {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 0;
	cursor: pointer;
	text-decoration: underline;
}

.login-box-element.bottom {
	margin-bottom: 0;
}

.login-button {
	width: 100%;
}

.login-legal-information-container {
	margin-bottom: 1em;
	cursor: pointer;
}

.login-legal-information-text {
	margin: 0px;
	font-size: 13px;
	height: 27px;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	white-space: pre;
}

.login-legal-information-text:hover {
	color: rgb(0, 0, 148);
}

.login-field {
	width: calc(100% - 8px);
	max-width: 322px;
}

.login-message {
	color: red;
}

.login-logo {
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 3;
}

.login-color {
	position: absolute;
	left: 0;
	top: 0;
	height: 10em;
	background-color: #1c4670;
	width: 100vw;
}

.account-info-text {
	margin: 5px 0px;
}

.account-info-show-loader-container {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
}

.account-info-checkbox {
	position: relative;
	top: 2px;
}

.logo {
	height: 10em;
	z-index: 1;
}

.order-error {
	color: red;
	font-weight: normal;
	white-space: pre-wrap;
}

.routeError {
	margin: 0px;
	color: red;
}

.prereqWrapper {
	height: 100%;
	width: 100%;
	border: 1px solid var(--off-color);
}

.prereqContainer {
	height: 35px;
	display: grid;
	align-items: center;
	width: 100%;
	cursor: pointer;
}

.prereqContainer:hover {
	background-color: var(--accent-color);
}

.prereqSelected {
	background-color: var(--secondary-color);
	cursor: initial;
}

.prereqSelected:hover {
	background-color: var(--secondary-color);
}

.prereqText {
	margin: 0px;
	margin-left: 5px;
}

@media screen and (max-width: 600px) {
	.reevaluate-box-calendar {
		margin-left: 0.5em;
		margin-bottom: 1em;
	}

	.reevaluate-box-case {
		margin-right: 0.5em;
	}

	.popup-reevaluate-content {
		grid-template-rows: auto 1fr;
		grid-template-columns: 1fr;
	}

	.reevaluate-popup {
		max-height: none;
		height: 90%;
	}
}

@media screen and (max-width: 800px) {

	.simulation {
		height: auto;
		min-height: 100vh;
		position: relative;
	}

	.simulation-popup-hidden {
		display: none;
	}

	.popup-container {
		height: 100%;
	}

	.popup-backdrop {
		height: 100%;
	}

	.review-orders-table-wrapper {
		min-height: 250px;
		height: auto;
	}

	.reevaluate-case-amounts {
		display: initial;
	}

	.simulation-end-button {
		display: initial;
	}

	.login-box {
		/* margin-top: 10em; */
	}

	.simulation-time-bar {
		height: auto;
	}

	.simulation-options {
		flex-direction: column;
	}

	.physical-options {
		width: 100vw;
	}
	
	.options-box {
		width: 90vw;
	}

	.review-orders-chart-tabs {
		flex-direction: column;
	}

	.review-orders-content {
		display: initial;
	}

	.location-popup {
		/* width: 100vw; */
	}
}

.feedbackTextarea {
	height: 150px;
	width: 100%;
	margin-top: 10px;
	resize: none;
	overflow-y: auto;
}

.login-success-message {
	color: rgb(20, 173, 20);
	font-weight: 500;
	margin-top: 10px;
}

.testModeWarningContainer {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 4;
	pointer-events: none;
}

.testModeWarningText {
	font-size: 26px;
	pointer-events: none;
	color: white;
}