

.testPrepCalculatorContainer {
    width: 100%;
}

.reportCardTestPrepModuleInputContainer {
    display: grid;
    padding: 15px 10px;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 10px;
}

.reportCardTestPrepModuleInputLabel {
    margin-right: 10px;
    font-size: 14px;
}

.reportCardTestPrepModuleInput {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.reportCardTestPrepModuleButton {
    margin: 0px 0px 0px 10px;
    cursor: pointer;
}

.reportCardTestPrepModuleLoadingContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    width: 69px;
}

.reportCardTestPrepModuleErrorText {
    margin: 10px 0px;
    margin-bottom: 0px;
    color: red;
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 4;
}

.testPrepCalculatorTitleValue {
    margin: 10px;
    width: calc(100% - 42px);
    margin-top: 0px;
}