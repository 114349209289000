body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Light mode colors */

:root {
	--primary-font-size: 16px;
	--secondary-font-size: 14px;
  --primary-color: #FCFCFC; 
  --accent-color: #e6e6e6;
  --button-background-color: white;
  --off-color: #c8c8c8;
	--secondary-color: #bbcad6;
	--hover-color: #9ebac0;
	--selected-button-color: #B7C3CC;
	--header-font-color: #0B335D;
  --primary-font-color: black;
  --border-color: black;
  --gradient: linear-gradient(to bottom, #eee 0%, #ccc 100%);
  --reverse-gradient: linear-gradient(to top, #eee 0%, #ccc 100%);

  /* Light mode */

  --new-primary-color: #0B335D;
  --new-secondary-color: #F79D23;
  --new-primary-background-color: #e7e7e7;
  --new-secondary-background-color: #ffffff;
  --new-tertiary-background-color: #3E3E3E;
  --new-off-background-color: #cecece;
  --new-text-color: #1d1d1d;
  --new-case-text-color: #1d1d1d;
  --new-secondary-text-color: #ffffff;
  --new-off-text-color: #686868;
  --new-secondary-off-text-color: #cecece;
  --new-tertiary-off-text-color: #1d1d1d;
  --case-selected-color: #e5ebf3;

  /* Dark Mode */

  /* --new-primary-color: #3670ae;
  --new-secondary-color: #F79D23;
  --new-primary-background-color: #252525;
  --new-secondary-background-color: #3c3c3c;
  --new-tertiary-background-color: #4a4a4a;
  --new-off-background-color: #cecece;
  --new-text-color: #ffffff;
  --new-case-text-color: #ffffff;
  --new-secondary-text-color: #ffffff;
  --new-off-text-color: #a4a4a4;
  --new-secondary-off-text-color: #cecece;
  --new-tertiary-off-text-color: #ffffff;
  --case-selected-color: #454b53; */

  /* Beach Theme */

  /* --new-primary-color: #266595;
  --new-secondary-color: #0e4976;
  --new-primary-background-color: #8b7555;
  --new-secondary-background-color: #ead6b6;
  --new-tertiary-background-color: #caac81;
  --new-off-background-color: #ccb188;
  --new-text-color: #3e2708;
  --new-case-text-color: #3e2708;
  --new-secondary-text-color: #fff8ef;
  --new-off-text-color: #8a6f46;
  --new-secondary-off-text-color: #5b3705;
  --new-tertiary-off-text-color: #f5ddbc;
  --case-selected-color: #e5ebf3; */

  /* Jungle Theme */

  /* --new-primary-color: #4c6c4c;
  --new-secondary-color: #a4ad27;
  --new-primary-background-color: #543e2f;
  --new-secondary-background-color: #a4886b;
  --new-tertiary-background-color: #293928;
  --new-off-background-color: #cecece;
  --new-text-color: #dfc3a2;
  --new-case-text-color: #f5ddc0;
  --new-secondary-text-color: #f9f0e7;
  --new-off-text-color: #ddcab3;
  --new-secondary-off-text-color: #ddb68d;
  --new-tertiary-off-text-color: #dfc3a2;
  --case-selected-color: #a88055; */
}

body {
  /* background-color: var(--new-primary-background-color); */
}

/* Simulation Dark mode colors */

/* :root {
	--primary-font-size: 16px;
	--secondary-font-size: 14px;
  --primary-color: #000000;
  --accent-color: #141414;
  --button-background-color: #575757;
  --off-color: #505050;
	--secondary-color: #292B2D;
	--hover-color: #266070;
	--selected-button-color: #266070;
	--header-font-color: white;
  --primary-font-color: white;
  --border-color: white;
  --gradient: linear-gradient(to bottom, rgb(46, 46, 46) 0%, rgb(68, 68, 68) 100%);
  --reverse-gradient: linear-gradient(to top, rgb(46, 46, 46) 0%, rgb(68, 68, 68) 100%);
} */

.app {
	width: 100%;
	height: 100%;
	user-select: none;
	display: -webkit-flexbox;
}

.checkboxContainer {
  background-color: var(--new-secondary-background-color);
  cursor: pointer;
  border-radius: 2px;
  height: 15px;
  width: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  align-items: center;
  justify-items: center;
}

.checkboxCheck {
  margin: 0px;
  color: var(--new-text-color);
  height: 12px;
  width: 12px;
}

.primaryButton, .secondaryButton, .tertiaryButton, .warningButton {
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border: 0px;
}

.primaryButton {
  background-color: var(--new-secondary-color);
  color: white;
}

.secondaryButton {
  background-color: var(--new-secondary-background-color);
  color: var(--new-text-color);
}

.tertiaryButton {
  background-color: var(--new-primary-color);
  color: white;
}

.warningButton {
  background-color: red !important;
  color: white !important;
}

.buttonDisabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}

.switchContainer {
  height: 35px;
  width: 60px;
  background-color: var(--new-primary-background-color);
  border-radius: 3px;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  border: 0px;
  cursor: pointer;
  transition-duration: 250ms;
}

.switchContainerToggled {
  background-color: var(--new-primary-color);
}

.switchButton {
  background-color: var(--new-secondary-background-color);
  height: 20px;
  width: 23px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition-duration: 250ms;
}

.standardLabel {
  color: var(--new-text-color);
  display: inline-block;
  margin-bottom: 5px;
}

.standardInput {
  height: 40px;
  width: calc(100% - 10px);
  padding-left: 10px;
  font-size: 16px;
  background-color: var(--new-primary-background-color);
  color: var(--new-text-color);
  border-radius: 3px;
  border: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  cursor: initial;
}

.standardTextArea {
  height: 150px;
  width: calc(100% - 10px);
  padding-left: 10px;
  padding-top: 10px;
  font-size: 14px;
  background-color: var(--new-primary-background-color);
  color: var(--new-text-color);
  border-radius: 3px;
  border: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  cursor: initial;
  resize: none;
  overflow-y: auto;
}

.popupCloseButton {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}

.popupCloseButton::before, .popupCloseButton::after {
  position: absolute;
  content: "";
  height: 20px;
  width: 2px;
  border-radius: 2px;
  background-color: var(--new-off-text-color);
}

.popupCloseButton::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.popupCloseButton::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.popupCloseButton:hover {
  background-color: rgba(0, 0, 0, 0);
}