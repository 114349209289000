.grading {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: auto 1fr;
}

.grading-case-list {
	height: 100%;
	width: 350px;
	max-width: 450px;
	margin-right: 1em;
	border-right: 1px solid #ccc;
	resize: horizontal;
	overflow: hidden;
}

@media(max-width: 800px) {
	.grading-case-list {
		width: 125px;
	}
}

.grading-case-list-content {
	flex-direction: column;
	overflow-y: auto;
	height: calc(100% - 60px);
}

@media(max-width: 800px) {
	.grading-case-list-content {
		height: calc(100% - 114px);
	}
}

.grading-case-list-date {
	display: flex;
	flex-direction: row;
	padding-left: 1em;
	cursor: pointer;
	align-items: center;
}

.grading-case-list-date:nth-child(even) {
	background: #F9F9F9;
}

.grading-case-list-date.true {
	background: #0095cb;
	cursor: auto;
}

.grading-case-list-header {
	font-size: 20px;
	padding: 16px;
	border-bottom: 1px solid #ccc;
}

.grading-view {
	width: auto;
	height: 100%;
	overflow-x: hidden;
	position: relative;
}

.grading-view-tabs {
	display: grid;
	margin-top: .5em;
	grid-template-columns: auto 1fr;
}

.grading-view-tabs-wrapper {
	display: flex;
	flex-direction: left;
}

.grading-view-tab {
	border: 1px solid #ccc;
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 5px;
	margin-right: 1px;
	width: fit-content;
	cursor: pointer;
	background: linear-gradient(to bottom, #eee 0%, #ccc 100%);
}

.grading-view-tab.true {
	background: white;
	border-bottom: none;
	border-radius: 5px 5px 0 0;
}

.grading-view-text {
	text-align: center;
	width: 100%;
	font-weight: bold;
}

@media(max-width: 700px) {
	.grading-view-tabs {
		grid-template-columns: auto;
		grid-template-rows: 1fr 1fr;
	}

	.grading-view-text {
		grid-column: span 3;
		grid-row: 1;
	}
}

.grading-feedback {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	flex: 1;
}

.grading-feedback-header {
	display: flex;
	flex-direction: row;
	margin-bottom: 1em;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 20px;
	padding: 16px;
	background: #eee;
	justify-content: space-around;
}

.grading-feedback-category {
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 1em;
}

.grading-feedback-category-header {
	margin-bottom: 1em;
	font-size: 20px;
	padding: 16px;
	border-bottom: 1px solid #ccc;
	background: #eee;
}

.grading-feedback-event {
	margin-left: 1em;
	margin-right: 1em;
	margin-bottom: 1em;
	padding: 1em;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.grading-feedback-event.incorrect {
	background: #f8d7da;
	color: #721c24;
	border: 1px solid #f8d7da;
}
.grading-feedback-event.optional {
	background: #d1ecf1;
	border: 1px solid #d1ecf1;
	color: #0c5460
}
.grading-feedback-event.correct {
	background: #d4edda;
	border: 1px solid #d4edda;
	color: #22561b;
}

.grading-feedback-event-header {
	font-size: 20px;
}

.grading-feedback-event-reason {
	font-weight: bold;
	white-space: pre-wrap;
}

.grading-feedback-summary {
	white-space: pre-wrap;
	margin-left: 1em;
	margin-right: 1em;
	margin-bottom: 1em;
}

.grading-view-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: .5em;
	border-left: 1px solid #ccc;
	border-top: 1px solid #ccc;
	position: sticky;
	bottom: 0;
	background-color: white;
}

.grading-log {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
}

.grading-log-headers {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: start;
	background: linear-gradient(to bottom, #eee 0%, #ccc 100%);
	width: 100%;
	font-weight: bold;
}

.grading-log-header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex: 1;
}

.grading-log-header.event {
	flex: 3;
}

.grading-log-content {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.grading-log-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
	padding: 4px 0px;
	align-items: center;
}

.grading-log-row:nth-child(even) {
	background: #f0f0f0;
}

.grading-log-column {
	flex: 1;
}

.grading-log-column-event {
	flex: 3;
}

@media screen and (max-width: 800px) {
	.grading-case-list-date {
		height: auto;
	}
}
