

.averageAttemptByCategoryModuleContainer {
    width: 95%;
    margin: auto;
    margin-top: 20px;
    display: grid;
    overflow: hidden;
    overflow-x: auto;
}

.averageAttemptByCategoryBodyWrapper {
    min-width: 550px;
}

.averageAttemptByCategoryTitleContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid #EEEEEE;
}

.averageAttemptByCategoryTitleText {
    font-size: 14px;
    margin: 10px 0px;
    justify-self: center;
    text-align: center;
    align-self: center;
}

.averageAttemptByCategoryBodyContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.averageAttemptByCategoryBodyContainer:nth-child(even) {
    background-color: #F8F8F8;
}

.averageAttemptByCategoryBodyContainer:nth-last-child(1) {
    border-radius: 0px 0px 5px 5px;
}

.averageAttemptByCategoryBodyText {
    font-size: 14px;
    margin: 10px 0px;
    justify-self: center;
    align-self: center;
    text-align: center;
}